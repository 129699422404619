import { useMutation, useQuery } from '@tanstack/react-query'
import {
    getUserColleagues,
    getUsersFromWorkspace,
    addUserToWorkspace,
    removeUserFromWorkspace,
    getWorkspaceData,
    updateWorkspaceWeekStartDay,
} from './accountApi'
import { getUserID } from '../../../utils'

export const useAccount = (selectedWorkspace) => {
    const userID = getUserID()

    const { data: userColleagues } = useQuery({
        queryKey: ['userColleagues', userID],
        queryFn: () => getUserColleagues(),
    })

    const {
        data: members,
        refetch,
        isLoading: loadingMembers,
    } = useQuery({
        queryKey: ['usersFromWorkspace', userID, selectedWorkspace],
        queryFn: () => getUsersFromWorkspace(selectedWorkspace),
        enabled: !!selectedWorkspace,
    })

    const { mutate: addUser, isPending: isAddingUser } = useMutation({
        mutationFn: ({ workspaceID, userID, role }) =>
            addUserToWorkspace(workspaceID, userID, role),
        onSuccess: () => refetch(),
    })

    const { mutate: changeRole, isPending: isChangingRole } = useMutation({
        mutationFn: ({ workspaceID, userID, role }) =>
            addUserToWorkspace(workspaceID, userID, role),
        onSuccess: (data, variables) => {
            variables?.onSuccess()
            refetch()
        },
    })

    const { mutate: removeUser, isPending: isRemovingUser } = useMutation({
        mutationFn: ({ workspaceID, userID }) => removeUserFromWorkspace(workspaceID, userID),
        onSuccess: (data, variables) => {
            variables?.onSuccess()
            refetch()
        },
    })

    const {
        data: workspaceData,
        refetch: refetchWorkspaceData,
        isLoading: isLoadingWorkspaceData,
        error: errorWorkspaceData,
    } = useQuery({
        queryKey: ['workspaceConfig', selectedWorkspace],
        queryFn: () => getWorkspaceData(selectedWorkspace),
    })

    const { mutate: updateWeekStartDay, isPending: isUpdatingWeekStartDay } = useMutation({
        mutationFn: (weekStartDay) => updateWorkspaceWeekStartDay(workspaceData, weekStartDay),
        onSuccess: () => {
            refetchWorkspaceData()
        },
    })

    return {
        userColleagues: userColleagues || [],
        members: members || [],
        loadingMembers,
        addUser,
        isAddingUser,
        removeUser,
        isRemovingUser,
        changeRole,
        isChangingRole,
        workspaceData,
        refetchWorkspaceData,
        isLoadingWorkspaceData,
        errorWorkspaceData,
        updateWeekStartDay,
        isUpdatingWeekStartDay,
    }
}
