import { sheetServerRequest } from '../../api'

export const getCalculatedFields = async (inboxID) => {
    const action = {
        action: 'getCalculatedFields',
        sheetRange: {
            inboxID,
        },
    }
    return await sheetServerRequest({
        method: 'post',
        data: action,
    })
}
