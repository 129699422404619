import React, { useEffect, useState } from 'react'
import './Account.css'
import {
    Box,
    MenuItem,
    Select,
    Table,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    TableSortLabel,
    Autocomplete,
    Skeleton,
} from '@mui/material'
import Button from '../../common/Button/Button'
import TextAa from '../../../assets/icons/TextAa.svg'
import TrashRed from '../../../assets/icons/TrashRed.svg'
import ArrowDown from '../../../assets/icons/CaretDownBlack.svg'
import TableSorting from '../../../assets/icons/TableSorting.svg'
import { renderSelector } from '../Explorer/DrawerTabs/Style/utils'
import IconButton from '@mui/material/IconButton'
import { useWorkspaces } from '../../../api/hooks/workspaces/useWorkspaces'
import { CreateEditWorkspaceModal } from '../Canvas/ActionsDialogs/CreateEditWorkspaceModal'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import { updateWorkspaceName } from '../../../store/actions/authActions'
import { setResetTrigger } from '../../../store/actions/uiActions'
import { useDispatch } from 'react-redux'
import { DeleteWorkspaceModal } from '../Canvas/ActionsDialogs/DeleteWorkspaceModal'
import TextField from '@mui/material/TextField'
import { useAccount } from '../../../api/hooks/account/useAccount'
import { ScoopLoader } from '../../common/Spinner/ScoopLoader'
import DeleteDialog from '../../common/Dialog/DeleteDialog'
import { getWorkspaceID } from '../../../utils'
import { useUserInfo } from '../../../api/hooks/user/useUserInfo'
import { Tab } from '../../common/Tab/Tab'
import { InfoTooltip } from '../../common/InfoTooltop/InfoTooltip'

const PERMISSION_OPTIONS = [
    {
        label: 'Owner',
        value: 'Owner',
    },
    {
        label: 'Admin',
        value: 'Admin',
    },
    {
        label: 'Member',
        value: 'Member',
    },
]

const DATE_PROPERTY_OPTIONS = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
]

export const Account = () => {
    const dispatch = useDispatch()
    const workspaceID = getWorkspaceID()
    const [selectedTab, setSelectedTab] = useState('members')
    const [selectedDateProperty, setSelectedDateProperty] = useState(DATE_PROPERTY_OPTIONS[0])
    const [selectedWorkspace, setSelectedWorkspace] = useState(null)
    const [selectedUser, setSelectedUser] = useState(null)
    const [openRenameModal, setOpenRenameModal] = useState(false)
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [userToRemove, setUserToRemove] = useState(null)
    const [loadingChangeRole, setLoadingChangeRole] = useState(null)
    const [selectedPermission, setSelectedPermission] = useState(PERMISSION_OPTIONS[0].value)
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' })
    const [snackbar, setSnackbar] = useState(null)

    const { user } = useUserInfo()
    const { workspaces } = useWorkspaces()
    const {
        members,
        loadingMembers,
        userColleagues,
        addUser,
        isAddingUser,
        removeUser,
        isRemovingUser,
        changeRole,
        workspaceData,
        updateWeekStartDay,
        isUpdatingWeekStartDay,
    } = useAccount(selectedWorkspace)

    const [sortedMembers, setSortedMembers] = useState(members)
    useEffect(() => {
        if (workspaceData?.weekStartDay !== undefined) {
            setSelectedDateProperty(DATE_PROPERTY_OPTIONS[workspaceData.weekStartDay - 1])
        }
    }, [workspaceData?.weekStartDay])

    useEffect(() => {
        if (workspaces.length > 0 && !selectedWorkspace) {
            setSelectedWorkspace(workspaces[0].workspaceID)
        }
    }, [workspaces])

    useEffect(() => {
        if (members) setSortedMembers(members)
    }, [members])

    const BUTTONS = [
        {
            icon: TextAa,
            label: 'Rename',
            onClick: () => setOpenRenameModal(true),
        },
        {
            icon: TrashRed,
            label: 'Delete',
            onClick: () => setOpenDeleteModal(true),
        },
    ]

    const sortData = (key) => {
        const direction = sortConfig.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc'
        setSortConfig({ key, direction })

        const sortedData = [...members].sort((a, b) => {
            if (a[key] < b[key]) return direction === 'asc' ? -1 : 1
            if (a[key] > b[key]) return direction === 'asc' ? 1 : -1
            return 0
        })
        setSortedMembers(sortedData)
    }

    const getWorkspaceName = (workspaceID) => {
        const workspace = workspaces.find((workspace) => workspace.workspaceID === workspaceID)
        return workspace ? workspace.name : ''
    }

    const handleChangeWorkspace = (workspaceID) => {
        setSelectedWorkspace(workspaceID)
    }

    const handleCreateWorkspaceSuccess = (newWorkspace, response) => {
        if (newWorkspace.workspaceID === workspaceID) {
            dispatch(setResetTrigger())
            dispatch(
                updateWorkspaceName(newWorkspace.workspaceName, newWorkspace.workspaceID, response)
            )
        }
        setOpenRenameModal(false)
        setSelectedWorkspace(newWorkspace.workspaceID)
    }

    const handleDeleteWorkspaceSuccess = () => {
        setOpenDeleteModal(false)
        setSelectedWorkspace(workspaces[0].workspaceID)
    }

    const handleAddUser = () => {
        addUser({
            workspaceID: selectedWorkspace,
            userID: selectedUser.value,
            role: selectedPermission,
        })
    }

    const handleRemoveUser = (member) => {
        const userID = member.userID
        removeUser({
            workspaceID: selectedWorkspace,
            userID,
            onSuccess: () => setUserToRemove(null),
        })
    }

    const handleRoleChange = (role, member) => {
        setLoadingChangeRole(member.userID)
        changeRole({
            workspaceID: selectedWorkspace,
            userID: member.userID,
            role: role,
            onSuccess: () => setTimeout(() => setLoadingChangeRole(null), 1500),
        })
    }

    const getRole = () => {
        return members.find((member) => member.email === user?.userName)?.role
    }

    const handleUpdateDateProperty = () => {
        updateWeekStartDay(DATE_PROPERTY_OPTIONS.indexOf(selectedDateProperty) + 1)
    }

    const columns = [
        {
            label: 'Member',
            key: 'email',
            sortable: true,
            render: (member) => (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <div>
                        <div style={{ fontWeight: '600', color: '#000' }}>{member.name}</div>
                        <div style={{ color: '#979099', fontSize: '12px' }}>{member.email}</div>
                    </div>
                </Box>
            ),
        },
        {
            label: 'Role',
            key: 'role',
            sortable: true,
            render: (member) => (
                <>
                    {loadingChangeRole === member.userID ? (
                        <ScoopLoader />
                    ) : ['Admin', 'Owner'].includes(getRole()) ? (
                        <Select
                            value={member.role}
                            onChange={(e) => handleRoleChange(e.target.value, member)}
                            variant="outlined"
                            sx={{
                                fontSize: '14px',
                                color: '#2B1630',
                                fontWeight: '400',
                                width: 'auto',
                                padding: 0,
                                lineHeight: '19px',
                                '.MuiOutlinedInput-notchedOutline': { border: 'none' },
                                '.MuiSelect-select': {
                                    padding: '0px',
                                    display: 'flex',
                                    alignItems: 'center',
                                },
                            }}
                            IconComponent={(props) => (
                                <img
                                    {...props}
                                    style={{ height: '18px', cursor: 'pointer' }}
                                    src={ArrowDown}
                                    alt="arrow-down"
                                />
                            )}
                        >
                            <MenuItem value="Admin">Admin</MenuItem>
                            <MenuItem value="Owner">Owner</MenuItem>
                            <MenuItem value="Member">Member</MenuItem>
                        </Select>
                    ) : (
                        <Typography>{member.role}</Typography>
                    )}
                </>
            ),
        },
        {
            label: '',
            key: 'actions',
            sortable: false,
            render: (member, index) =>
                ['Admin', 'Owner'].includes(getRole()) ? (
                    <IconButton onClick={() => setUserToRemove(member)}>
                        <img
                            src={TrashRed}
                            alt="delete"
                            style={{ width: '20px', height: '20px' }}
                        />
                    </IconButton>
                ) : null,
        },
    ]

    const tabs = [
        { key: 'members', label: 'Members' },
        { key: 'config', label: 'Configuration' },
    ]

    const renderTabContent = () => {
        switch (selectedTab) {
            case 'members':
                return (
                    <>
                        <Box sx={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
                            <Typography
                                sx={{
                                    fontSize: '20px',
                                    fontWeight: '400',
                                    color: '#2B1630',
                                    lineHeight: '19px',
                                    fontFamily: 'Inter',
                                }}
                            >
                                {getWorkspaceName(selectedWorkspace)}
                            </Typography>
                            {getRole() === 'Owner' &&
                                BUTTONS.map((button, index) => (
                                    <Button
                                        onClick={button.onClick}
                                        key={index}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '8px',
                                        }}
                                        className={'button-grey small primary-button'}
                                    >
                                        <img
                                            style={{ width: '20px' }}
                                            src={button.icon}
                                            alt={button.label}
                                        />
                                        {button.label}
                                    </Button>
                                ))}
                        </Box>
                        {['Owner', 'Admin'].includes(getRole()) && (
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
                                <Box sx={{ display: 'flex', gap: '8px', alignItems: 'flex-end' }}>
                                    <Box sx={{ flex: 1 }}>
                                        <Typography
                                            sx={{
                                                fontSize: '12px',
                                                fontWeight: 600,
                                                fontFamily: 'Inter',
                                                margin: 0,
                                            }}
                                        >
                                            Add member
                                        </Typography>
                                        <Autocomplete
                                            onChange={(e, value) => setSelectedUser(value)}
                                            sx={{
                                                '& .MuiAutocomplete-inputRoot': {
                                                    padding: '0 4px !important',
                                                },
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label=""
                                                    variant={'outlined'}
                                                    sx={{
                                                        height: '38px',
                                                        marginTop: '0 !important',
                                                        '& .MuiOutlinedInput-root': {
                                                            height: '38px',
                                                            marginTop: '0',
                                                            '&.Mui-focused fieldset': {
                                                                borderColor: '#e50b54',
                                                            },
                                                            '& input': { padding: '0' },
                                                        },
                                                    }}
                                                />
                                            )}
                                            options={userColleagues}
                                            renderOption={(props, option, { selected }) => (
                                                <li {...props} key={option.value}>
                                                    <span>{option.label}</span>
                                                </li>
                                            )}
                                        />
                                    </Box>
                                    <Box sx={{ width: '20%' }}>
                                        {renderSelector(
                                            'Permission',
                                            selectedPermission,
                                            setSelectedPermission,
                                            PERMISSION_OPTIONS
                                        )}
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'flex-end',
                                            height: '100%',
                                        }}
                                    >
                                        <Button
                                            disabled={!selectedUser || isAddingUser}
                                            onClick={handleAddUser}
                                            sx={{ height: '38px' }}
                                            className={'button-purple small primary-button'}
                                        >
                                            {isAddingUser ? <ScoopLoader /> : 'Add user'}
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        )}
                        <Box sx={{ overflow: 'auto' }}>
                            <Table sx={{ borderCollapse: 'separate', width: '100%' }}>
                                <TableHead
                                    sx={{
                                        position: 'sticky',
                                        top: 0,
                                        zIndex: 999,
                                        backgroundColor: '#F2F2F2',
                                    }}
                                >
                                    <TableRow>
                                        {columns.map((column, index) => (
                                            <TableCell
                                                key={column.key}
                                                sx={{
                                                    fontWeight: 'bold',
                                                    fontSize: '14px',
                                                    color: '#000',
                                                    borderBottom: '1px solid #E0E0E0',
                                                    ...(column.key === 'name' && {
                                                        borderTopLeftRadius: '5px',
                                                    }),
                                                    ...(column.key === 'actions' && {
                                                        borderTopRightRadius: '5px',
                                                    }),
                                                    width:
                                                        index === 0
                                                            ? '50%'
                                                            : index === 1
                                                              ? '30%'
                                                              : '20%',
                                                }}
                                            >
                                                {column.sortable ? (
                                                    <TableSortLabel
                                                        active={sortConfig.key === column.key}
                                                        direction={
                                                            sortConfig.key === column.key
                                                                ? sortConfig.direction
                                                                : 'asc'
                                                        }
                                                        onClick={() => sortData(column.key)}
                                                        hideSortIcon={false}
                                                        IconComponent={() => (
                                                            <img
                                                                src={TableSorting}
                                                                alt="sort"
                                                                style={{
                                                                    height: '14px',
                                                                    transform:
                                                                        sortConfig.key ===
                                                                            column.key &&
                                                                        sortConfig.direction ===
                                                                            'desc'
                                                                            ? 'rotate(180deg)'
                                                                            : 'rotate(0deg)',
                                                                    transition:
                                                                        'transform 0.2s ease',
                                                                }}
                                                            />
                                                        )}
                                                    >
                                                        {column.label}
                                                    </TableSortLabel>
                                                ) : (
                                                    column.label
                                                )}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                {loadingMembers
                                    ? [...Array(5)].map((_, index) => (
                                          <TableRow key={index}>
                                              {columns.map((column) => (
                                                  <TableCell key={column.key}>
                                                      <Skeleton
                                                          variant="text"
                                                          width="100%"
                                                          height={34}
                                                      />
                                                  </TableCell>
                                              ))}
                                          </TableRow>
                                      ))
                                    : sortedMembers.map((member, index) => (
                                          <TableRow key={index}>
                                              {columns.map((column) => (
                                                  <TableCell
                                                      key={column.key}
                                                      sx={{
                                                          borderBottom: '1px solid #E0E0E0',
                                                          textAlign:
                                                              column.key === 'actions'
                                                                  ? 'right'
                                                                  : 'left',
                                                      }}
                                                  >
                                                      {column.render(member, index)}
                                                  </TableCell>
                                              ))}
                                          </TableRow>
                                      ))}
                            </Table>
                        </Box>
                    </>
                )
            case 'config':
                return (
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                        <Typography
                            sx={{
                                fontSize: '16px',
                                fontWeight: '400',
                                color: '#2B1630',
                                lineHeight: '19px',
                                fontFamily: 'Inter',
                            }}
                        >
                            Date Properties
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: '12px',
                                fontWeight: '400',
                                color: '#2B1630',
                                lineHeight: '19px',
                                fontFamily: 'Inter',
                            }}
                        >
                            Below you will find settings to adjust how dates are aggregated within
                            Scoop.
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                gap: '8px',
                                alignItems: 'flex-end',
                                maxWidth: '250px',
                                opacity: !['Admin', 'Owner'].includes(getRole()) ? 0.5 : 1,
                                pointerEvents: !['Admin', 'Owner'].includes(getRole())
                                    ? 'none'
                                    : 'auto',
                                cursor: !['Admin', 'Owner'].includes(getRole())
                                    ? 'not-allowed'
                                    : 'auto',
                            }}
                        >
                            {renderSelector(
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        width: '200px',
                                    }}
                                >
                                    <span>Calendar Week End:</span>
                                    <InfoTooltip
                                        arrow={true}
                                        placement={'top'}
                                        title={
                                            'Week end specifies how you choose to aggregate at the weekly level (e.g Saturday means Sunday to Saturday)'
                                        }
                                        componentsProps={{
                                            tooltip: {
                                                sx: {
                                                    backgroundColor: '#201024',
                                                    width: '500px',
                                                },
                                            },
                                            arrow: { sx: { color: '#201024' } },
                                        }}
                                    />
                                </div>,
                                selectedDateProperty,
                                setSelectedDateProperty,
                                DATE_PROPERTY_OPTIONS
                            )}
                            {['Admin', 'Owner'].includes(getRole()) && (
                                <Button
                                    onClick={handleUpdateDateProperty}
                                    style={{ height: '38px' }}
                                    className={'button-purple small primary-button'}
                                    disabled={isUpdatingWeekStartDay}
                                >
                                    {isUpdatingWeekStartDay ? <ScoopLoader /> : 'Update'}
                                </Button>
                            )}
                        </Box>
                    </Box>
                )
            default:
                return null
        }
    }

    return (
        <>
            <Box className={'account-screen-container'}>
                <Box>
                    <Typography
                        sx={{
                            fontSize: '28px',
                            fontWeight: '400',
                            color: '#000',
                            lineHeight: '31px',
                            fontFamily: 'Inter',
                        }}
                    >
                        Account Settings
                    </Typography>
                    <Typography>
                        <Typography
                            sx={{
                                fontSize: '14px',
                                fontWeight: '400',
                                color: '#979099',
                                lineHeight: '19px',
                                fontFamily: 'Inter',
                                marginTop: '4px',
                            }}
                        >
                            Here you can edit your profile, update your notifications preferences
                            and manage your Scoop Plan.
                        </Typography>
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', gap: '32px', height: '90%' }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '16px',
                            width: '212px',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '12px',
                                fontWeight: '600',
                                color: '#979099',
                                lineHeight: '17px',
                                fontFamily: 'Inter',
                                padding: '0 12px',
                            }}
                        >
                            Workspaces
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '8px',
                                maxHeight: '500px',
                                overflow: 'auto',
                            }}
                        >
                            {workspaces.length === 0
                                ? [...Array(10)].map((_, index) => (
                                      <Skeleton
                                          key={index}
                                          variant="rectangular"
                                          width={'212px'}
                                          height={40}
                                          sx={{ borderRadius: '5px' }}
                                      />
                                  ))
                                : workspaces.map((workspace, index) => (
                                      <Box
                                          key={index}
                                          onClick={() =>
                                              handleChangeWorkspace(workspace.workspaceID)
                                          }
                                          sx={{
                                              padding: '10px 12px',
                                              backgroundColor:
                                                  selectedWorkspace === workspace.workspaceID
                                                      ? '#FCE7EE'
                                                      : 'transparent',
                                              borderRadius: '5px',
                                              border:
                                                  selectedWorkspace === workspace.workspaceID &&
                                                  '1px solid #FFA6C3',
                                              cursor: 'pointer',
                                              width: '212px',
                                              overflowX: 'hidden',
                                              minHeight: '40px',
                                              whiteSpace: 'nowrap',
                                              textOverflow: 'ellipsis',
                                          }}
                                      >
                                          <Typography
                                              sx={{
                                                  fontSize: '14px',
                                                  fontWeight: '400',
                                                  color:
                                                      selectedWorkspace === workspace.workspaceID
                                                          ? '#E50B54'
                                                          : '#2B1630',
                                                  lineHeight: '19px',
                                                  fontFamily: 'Inter',
                                                  overflow: 'hidden',
                                                  whiteSpace: 'nowrap',
                                                  textOverflow: 'ellipsis',
                                              }}
                                          >
                                              {workspace.name}
                                          </Typography>
                                      </Box>
                                  ))}
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            height: '100%',
                            flex: 1,
                            backgroundColor: 'white',
                            borderRadius: '5px',
                            border: '1px solid #F2F2F2',
                            padding: '0px',
                        }}
                    >
                        <Box sx={{ width: '50%' }}>
                            <Tab
                                tabs={tabs}
                                value={selectedTab}
                                onChange={(e, newValue) => setSelectedTab(newValue)}
                            />
                        </Box>
                        <Box
                            sx={{
                                padding: '24px',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '16px 32px',
                                height: '100%',
                                overflow: 'auto',
                            }}
                        >
                            {renderTabContent()}
                        </Box>
                    </Box>
                </Box>
            </Box>
            {openRenameModal && (
                <CreateEditWorkspaceModal
                    open={openRenameModal}
                    close={() => setOpenRenameModal(false)}
                    initialName={getWorkspaceName(selectedWorkspace)}
                    workspaceID={selectedWorkspace}
                    actionType="renameWorkspace"
                    title="Rename workspace"
                    buttonText="Rename Workspace"
                    setSnackbar={setSnackbar}
                    onSuccess={handleCreateWorkspaceSuccess}
                    onError={(msg) => setSnackbar({ msg, severity: 'error' })}
                    isCreate={false}
                />
            )}
            {openDeleteModal && (
                <DeleteWorkspaceModal
                    open={openDeleteModal}
                    close={handleDeleteWorkspaceSuccess}
                    workspaceName={getWorkspaceName(selectedWorkspace)}
                    workspaceID={selectedWorkspace}
                    setSnackbar={setSnackbar}
                />
            )}
            {snackbar && (
                <Snackbar
                    open={!!snackbar}
                    autoHideDuration={5000}
                    onClose={() => setSnackbar(null)}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                >
                    <Alert
                        onClose={() => setSnackbar(null)}
                        severity={snackbar?.severity}
                        variant="filled"
                    >
                        {snackbar?.msg}
                    </Alert>
                </Snackbar>
            )}
            {!!userToRemove && (
                <DeleteDialog
                    open={!!userToRemove}
                    title={`${userToRemove.userName}`}
                    description={`Are you sure you want to remove ${userToRemove.userName} from ${getWorkspaceName(selectedWorkspace)}?`}
                    handleDelete={() => handleRemoveUser(userToRemove)}
                    handleCancel={() => setUserToRemove(null)}
                    isLoading={isRemovingUser}
                />
            )}
        </>
    )
}
