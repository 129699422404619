import { useSelector } from 'react-redux'
import React, { useEffect, useState, useRef } from 'react'
import {
    Box,
    FormControlLabel,
    FormGroup,
    Grid,
    Typography,
    IconButton,
    Paper,
    Skeleton,
    Popover,
} from '@mui/material'
import Button from '../../../common/Button/Button'
import Sheetlet from '../../../Source/Sheetlet'
import { Switch } from '../../../common/Switch/Switch'
import { renderToString } from 'react-dom/server'
import { ServerContext } from '../../../Source/SheetState'
import { Server } from '../../../../api/Server'
import { EmptyCalculatedColumns } from './EmptyCalculatedColumns'
import googleSheetsIcon from '../../../../assets/icons/GoogleSheets.svg'
import TrashRed from '../../../../assets/icons/TrashRed.svg'
import PlusIcon from '../../../../assets/icons/PlusWhite.svg'
import PlusRed from '../../../../assets/icons/PlusRed.svg'
import MagicWand from '../../../../assets/icons/MagicWandRed.svg'
import MagnifyingGlass from '../../../../assets/icons/MagnifyingGlassPink.svg'
import { useCalculatedFields } from '../../../../api/hooks/calculatedField/useCalculatedFields'
import DeleteDialog from '../../../common/Dialog/DeleteDialog'
import { DialogItem } from '../../../common/DialogItem/DialogItem'
import Dialog from '../../../common/Dialog/Dialog'
import Input from '../../../common/Input/Input'

export const CalculatedColumns = ({ inboxID }) => {
    const userID = useSelector((state) => state.auth.userID)
    const workspaceID = useSelector((state) => state.auth.workspaceID)
    const token = useSelector((state) => state.auth.token)
    const [previewData, setPreviewData] = useState(null)
    const [initialized, setInitialized] = useState(false)
    const [loading, setLoading] = useState(false)
    const [currentServerContext, setCurrentServerContext] = useState(null)
    const [worksheetID, setWorksheetID] = useState(null)
    const [sheetRange, setSheetRange] = useState({
        sheetType: 'CalculatedField',
        advanced: false,
        inboxID: inboxID,
    })
    const [editMode, setEditMode] = useState(false)
    const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false)
    const [openAddFormula, setOpenAddFormula] = useState(false)
    const [selectedFormula, setSelectedFormula] = useState(null)
    const [openAddFormulaWithAI, setOpenAddFormulaWithAI] = useState(false)
    const [openAddFormulaFromBlank, setOpenAddFormulaFromBlank] = useState(false)
    const [prompt, setPrompt] = useState('')
    const [formula, setFormula] = useState('')
    const [formulaName, setFormulaName] = useState('')
    const { data: calculatedFieldsData, isLoading: calculatedFieldsLoading } =
        useCalculatedFields(inboxID)
    const anchorEl = useRef(null)

    useEffect(() => {
        const css = new ServerContext(new Server(workspaceID, userID, token))
        setCurrentServerContext(css)
    }, [workspaceID, userID, token])

    useEffect(() => {
        if (!initialized && currentServerContext?.server) {
            sheetRange.inboxID = inboxID
            setSheetRange({ ...sheetRange })
            setLoading(true)
            setInitialized(true)
        }
    }, [inboxID, initialized, currentServerContext, sheetRange])

    const handleSave = () => {
        try {
            currentServerContext.server.sheetPostData(
                {
                    action: 'flushWorksheetCache',
                    sheetRange: {
                        inboxID: inboxID,
                    },
                },
                () => {}
            )
        } catch (e) {
            console.error(e)
        }
        setPreviewData(null)
        setInitialized(false)
    }

    const handleDelete = () => {
        // TODO: Implement delete functionality
        console.log('Delete column:', selectedFormula)
    }

    const handleGenerateFormulaWithAI = () => {
        console.log('Generate formula with AI', prompt)
    }

    const handleCreateFormulaFromBlank = () => {
        console.log('Create formula from blank', formula)
    }

    const handlePreviewFormula = () => {
        console.log('Preview formula', formula)
    }

    return (
        <Box sx={{ width: '100%', height: '100%' }}>
            {!previewData && !loading ? (
                <EmptyCalculatedColumns inboxID={inboxID} />
            ) : (
                currentServerContext && (
                    <Box sx={{ height: '100%' }}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '16px',
                                    mb: '16px',
                                    mt: '16px',
                                }}
                            >
                                <Typography
                                    className={'inter'}
                                    sx={{ fontSize: '14px', fontWeight: 600 }}
                                >
                                    Worksheet Edit
                                </Typography>
                                <Switch
                                    checked={editMode}
                                    onChange={(event) => {
                                        setEditMode(!editMode)
                                    }}
                                />
                            </Box>
                            {!editMode && (
                                <Box ref={anchorEl} sx={{ mb: 2, display: 'flex', gap: 1 }}>
                                    <Button
                                        startIcon={<img src={PlusIcon} alt="Add" />}
                                        className="button-purple small"
                                        onClick={() => setOpenAddFormula(true)}
                                    >
                                        Add Formula
                                    </Button>
                                </Box>
                            )}
                        </Box>

                        {calculatedFieldsLoading ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '100%',
                                    gap: '16px',
                                    flexDirection: 'column',
                                }}
                            >
                                {[1, 2, 3, 4, 5, 6, 7].map((item) => (
                                    <Skeleton
                                        key={item}
                                        variant="rectangular"
                                        width="100%"
                                        height={34}
                                    />
                                ))}
                            </Box>
                        ) : !editMode ? (
                            calculatedFieldsData && calculatedFieldsData.length > 0 ? (
                                <Paper
                                    elevation={0}
                                    sx={{
                                        p: 1,
                                        backgroundColor: '#F8F9FA',
                                        borderRadius: '8px',
                                    }}
                                >
                                    <Grid container spacing={2}>
                                        {calculatedFieldsData.map((calculatedField) => (
                                            <Grid item xs={12} key={calculatedField.columnName}>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'space-between',
                                                        p: 1,
                                                        backgroundColor: 'white',
                                                        borderRadius: '4px',
                                                        border: '1px solid #E6E4E6',
                                                    }}
                                                >
                                                    <Box sx={{ flex: 1 }}>
                                                        <Typography
                                                            sx={{
                                                                fontSize: '14px',
                                                                fontWeight: 600,
                                                                color: '#1A1A1A',
                                                                mb: 0.5,
                                                            }}
                                                        >
                                                            {calculatedField.columnName}
                                                        </Typography>
                                                        <Typography
                                                            sx={{
                                                                fontSize: '12px',
                                                                color: '#635566',
                                                            }}
                                                        >
                                                            {calculatedField.complex
                                                                ? 'Complex Formula'
                                                                : calculatedField.formula}
                                                        </Typography>
                                                    </Box>
                                                    <IconButton
                                                        onClick={() => {
                                                            setOpenDeleteConfirmation(true)
                                                            setSelectedFormula(calculatedField)
                                                        }}
                                                        sx={{ color: '#635566' }}
                                                    >
                                                        <img src={TrashRed} alt="Delete" />
                                                    </IconButton>
                                                </Box>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Paper>
                            ) : (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: '100%',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: '16px',
                                            fontWeight: 400,
                                            color: '#635566',
                                        }}
                                    >
                                        No formulas found
                                    </Typography>
                                </Box>
                            )
                        ) : (
                            <div>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        width: '100%',
                                        gap: '16px',
                                        marginBottom: '16px',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            flex: '2',
                                            color: '#635566',
                                            fontSize: '14px',
                                        }}
                                    >
                                        Create and edit custom calculated columns that can be added
                                        to your raw report source data. You can also choose to not
                                        include columns from your raw reports.
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '16px',
                                        }}
                                    >
                                        <FormGroup>
                                            <FormControlLabel
                                                sx={{
                                                    color: 'text.secondary',
                                                    padding: '10px',
                                                }}
                                                control={
                                                    <Switch
                                                        checked={sheetRange.advanced}
                                                        onChange={(event) => {
                                                            sheetRange.advanced =
                                                                !sheetRange.advanced
                                                            setSheetRange({
                                                                ...sheetRange,
                                                            })
                                                        }}
                                                    />
                                                }
                                                label="Advanced"
                                            />
                                        </FormGroup>
                                        <Box sx={{ flex: '1' }}>
                                            <Button
                                                sx={{ float: 'right' }}
                                                className={'button-purple small'}
                                                onClick={handleSave}
                                            >
                                                Save Changes
                                            </Button>
                                        </Box>
                                    </Box>
                                </Box>
                                <Sheetlet
                                    controlledHeight={false}
                                    setWorksheetID={setWorksheetID}
                                    serverContext={currentServerContext}
                                    sheetRange={sheetRange}
                                    locked={!sheetRange.advanced}
                                    embeddedSizeProps={{ height: 'auto' }}
                                    cornerHTML={
                                        worksheetID
                                            ? `<a href="https://docs.google.com/spreadsheets/d/${worksheetID}" target="_blank">${renderToString(
                                                  <img
                                                      alt={'Google sheets'}
                                                      src={googleSheetsIcon}
                                                  />
                                              )}</a>`
                                            : ''
                                    }
                                />
                            </div>
                        )}
                    </Box>
                )
            )}
            {openDeleteConfirmation && (
                <DeleteDialog
                    open={openDeleteConfirmation}
                    setOpen={setOpenDeleteConfirmation}
                    handleDelete={handleDelete}
                    handleCancel={() => setOpenDeleteConfirmation(false)}
                    onClose={() => setOpenDeleteConfirmation(false)}
                    title="Formula"
                    description="Are you sure you want to delete this formula?"
                />
            )}
            {openAddFormula && (
                <Popover
                    open={openAddFormula}
                    anchorEl={anchorEl.current}
                    onClose={() => setOpenAddFormula(false)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    sx={{
                        marginTop: '8px',
                        boxShadow: '0px 0px 7px 0px rgba(20, 9, 42, 0.25)',
                    }}
                >
                    <Box sx={{ padding: '4px 0', borderRadius: '5px' }}>
                        <DialogItem
                            description={'Add a new formula to your dataset'}
                            title={'Create a new formula'}
                            icon={PlusRed}
                            onClick={() => setOpenAddFormulaFromBlank(true)}
                        />
                        <DialogItem
                            description={'Create a new formula using AI'}
                            title={'Create a new formula with AI'}
                            onClick={() => setOpenAddFormulaWithAI(true)}
                            icon={MagicWand}
                        />
                        <DialogItem
                            disabledStyles={true}
                            description={'Create a lookup formula from another dataset'}
                            title={'Create a lookup formula'}
                            onClick={() => setOpenAddFormulaFromBlank(true)}
                            icon={MagnifyingGlass}
                        />
                    </Box>
                </Popover>
            )}
            {(openAddFormulaWithAI || openAddFormulaFromBlank) && (
                <Dialog
                    style={{ width: '500px' }}
                    open={openAddFormulaWithAI || openAddFormulaFromBlank}
                    onClose={() => {
                        setOpenAddFormulaWithAI(false)
                        setOpenAddFormulaFromBlank(false)
                    }}
                    title={
                        openAddFormulaWithAI
                            ? 'Create a new formula with AI'
                            : 'Create a new formula from scratch'
                    }
                    actions={
                        <Box display="flex" justifyContent="flex-end" width="100% " gap="8px">
                            <Button
                                className={'button-grey small'}
                                onClick={() => {
                                    setOpenAddFormulaWithAI(false)
                                    setOpenAddFormulaFromBlank(false)
                                }}
                                text={'Cancel'}
                            />
                            <Button
                                onClick={
                                    openAddFormulaWithAI
                                        ? handleGenerateFormulaWithAI
                                        : handleCreateFormulaFromBlank
                                }
                                text={openAddFormulaWithAI ? 'Generate' : 'Create'}
                                className={'button-purple small'}
                            />
                        </Box>
                    }
                >
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                        <Box>
                            <p
                                style={{ fontSize: '12px', fontWeight: '600', margin: '0 0 4px 0' }}
                                className={'inter'}
                            >
                                Formula name
                            </p>
                            <Input
                                value={formulaName}
                                onChange={(e) => setFormulaName(e.target.value)}
                                placeholder="Enter formula name"
                            />
                        </Box>
                        <Box>
                            <p
                                style={{ fontSize: '12px', fontWeight: '600', margin: '0 0 4px 0' }}
                                className={'inter'}
                            >
                                {openAddFormulaWithAI
                                    ? 'Type your prompt here'
                                    : 'Type your formula here'}
                            </p>
                            <Input
                                value={openAddFormulaWithAI ? prompt : formula}
                                onChange={(e) =>
                                    openAddFormulaWithAI
                                        ? setPrompt(e.target.value)
                                        : setFormula(e.target.value)
                                }
                                multiline
                                rows={openAddFormulaWithAI ? 4 : 1}
                                placeholder={
                                    openAddFormulaWithAI
                                        ? 'Start typing your prompt here...'
                                        : 'Start typing your formula here...'
                                }
                                fullWidth
                                padding={'0'}
                            />
                        </Box>
                        {openAddFormulaFromBlank && (
                            <Button
                                className={'button-grey small'}
                                onClick={() => handlePreviewFormula()}
                            >
                                Preview
                            </Button>
                        )}
                    </Box>
                </Dialog>
            )}
        </Box>
    )
}
