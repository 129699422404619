import { useQuery } from '@tanstack/react-query'
import { getCalculatedFields } from './calculatedFieldsApi'

export const useCalculatedFields = (inboxID) => {
    const { data, isLoading, error } = useQuery({
        queryKey: ['calculatedFields', inboxID],
        queryFn: () => getCalculatedFields(inboxID),
    })

    return {
        data: data?.calculatedFields || [],
        isLoading,
        error,
    }
}
