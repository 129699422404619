import Dialog from '../../../common/Dialog/Dialog'
import React, { useEffect, useRef, useState } from 'react'
import Button from '../../../common/Button/Button'
import { Box, Typography } from '@mui/material'
import FileDashed from '../../../../assets/icons/FileDashed.svg'
import PlusRed from '../../../../assets/icons/PlusRed.svg'
import Input from '../../../common/Input/Input'
import RecipeThumbnail from '../../../../assets/images/RecipeThumbnail.svg'
import { RecipeCard } from '../../Recipe/RecipeFlow/ChooseRecipe'
import { Server } from '../../../../api/Server'
import { useDispatch, useSelector } from 'react-redux'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../../../router/routes'
import { ScoopLoader } from '../../../common/Spinner/ScoopLoader'
import { useCanvases } from '../../../../api/hooks/canvases/useCanvases'
import { WorkspaceBox } from '../../Login/CreateWorkspace'
import { MAX_POLLING_ATTEMPTS, useIngestionMetadata } from '../../../../api/hooks/datasource/hook'
import { useQueryClient } from '@tanstack/react-query'
import { setCanvasPolling } from '../../../../store/actions/uiActions'

const recipes = [
    {
        img: RecipeThumbnail,
        department: 'Marketing',
        title: 'Deal velocity',
        description:
            'This recipe provides you with average time between stages and is filterable by any field available within the data source. These filters allow you look at deal velocity by fields like Deal Owner, Deal Source and Deal Type.',
        stats: ['1 dataset', '2 canvases'],
    },
    {
        img: RecipeThumbnail,
        department: 'Finance',
        title: 'Budget Analysis',
        description:
            'This recipe analyzes your budget data and provides insights into spending trends, allocation efficiency, and budget performance over time.',
        stats: ['3 datasets', '1 canvas'],
    },
    {
        img: RecipeThumbnail,
        department: 'Human Resources',
        title: 'Employee Satisfaction',
        description:
            'This recipe measures employee satisfaction through surveys and analyzes the results to identify areas for improvement and track changes over time.',
        stats: ['2 datasets', '3 canvases'],
    },
    {
        img: RecipeThumbnail,
        department: 'Operations',
        title: 'Inventory Optimization',
        description:
            'This recipe helps optimize inventory levels by analyzing historical data, demand forecasts, and supply chain disruptions to ensure efficient inventory management.',
        stats: ['2 datasets', '2 canvases'],
    },
    {
        img: RecipeThumbnail,
        department: 'Customer Service',
        title: 'Customer Feedback Analysis',
        description:
            'This recipe analyzes customer feedback from various channels such as surveys, reviews, and social media to identify trends, sentiment, and areas for improvement in customer service.',
        stats: ['3 datasets', '2 canvases'],
    },
    {
        img: RecipeThumbnail,
        department: 'Product Development',
        title: 'Feature Prioritization',
        description:
            'This recipe prioritizes product features based on customer feedback, market demand, and strategic goals to optimize product development efforts and maximize impact.',
        stats: ['2 datasets', '1 canvas'],
    },
    {
        img: RecipeThumbnail,
        department: 'Sales',
        title: 'Pipeline Analysis',
        description:
            'This recipe analyzes the sales pipeline to identify bottlenecks, opportunities, and potential risks, enabling better sales forecasting and decision-making.',
        stats: ['3 datasets', '2 canvases'],
    },
]

export const AIFlow = ({ inboxID, goBack, onClose }) => {
    const workspaceID = useSelector((state) => state.auth.workspaceID)
    const userID = useSelector((state) => state.auth.userID)
    const token = useSelector((state) => state.auth.token)
    const queryClient = useQueryClient()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const server = new Server(workspaceID, userID, token)
    const canvasesAmountRef = useRef(null)
    const { canvases, refetch, generateCanvasWithAI } = useCanvases()
    const [step, setStep] = useState(0)
    const [incompleteError, setIncompleteError] = useState(false)
    const [snackbarOpen, setSnackbarOpen] = useState(null)
    const [checkedOption, setCheckedOption] = useState(null)
    const [input, setInput] = useState({
        dataDescription: '',
        interest: '',
    })

    const handleInputChange = (key, value) => {
        setInput({
            ...input,
            [key]: value,
        })
    }

    const FLOW_STEPS = [
        {
            title: 'Jumpstart your insights',
            description:
                'Instantly unlock the power of your data. Choose how you’d like to get started:',
            children: (
                <Box style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                    <Box style={{ display: 'flex', gap: '16px', justifyContent: 'center' }}>
                        <WorkspaceBox
                            onClick={() => setCheckedOption(0)}
                            icon={FileDashed}
                            title={'AI-Powered Insights'}
                            subtitle={
                                'Let AI analyze your data and surface key takeaways instantly.'
                            }
                            selected={checkedOption === 0}
                        />
                        <WorkspaceBox
                            onClick={() => setCheckedOption(1)}
                            icon={PlusRed}
                            title={'Start working freely'}
                            subtitle={'Explore data in your own way'}
                            selected={checkedOption === 1}
                        />
                    </Box>
                </Box>
            ),
        },
        {
            title: 'Generate Canvas from AI',
            description:
                'Get AI-powered insights tailored to your needs. Tell us a little about your data, and we’ll generate a personalized canvas in seconds.',
            children: (
                <Box style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                    <Input
                        label={'What kind of data are you working with?'}
                        placeholder={'Briefly describe your dataset'}
                        rows={4}
                        multiline
                        onChange={(event) =>
                            handleInputChange('dataDescription', event.target.value)
                        }
                    />
                    <Input
                        label={'What insights are you looking for?'}
                        placeholder={'Tell us what you want to uncover'}
                        rows={3}
                        multiline
                        onChange={(event) => handleInputChange('interest', event.target.value)}
                    />
                    {incompleteError && (
                        <Typography
                            sx={{
                                fontFamily: 'Inter',
                                fontWeight: 400,
                                fontSize: '14px',
                                color: '#E50B54',
                            }}
                        >
                            {incompleteError}
                        </Typography>
                    )}
                </Box>
            ),
        },
        {
            title: 'Scoop Recipes',
            description:
                'Pick from the below list of recipes for [CONNECTOR]. If you prefer not to use any of the recipes below, simply click Next.',
            children: (
                <Box
                    style={{
                        display: 'flex',
                        gap: '24px',
                        flexWrap: 'wrap',
                        maxHeight: '400px',
                        overflowY: 'auto',
                    }}
                >
                    {recipes.map((recipe, index) => (
                        <RecipeCard recipe={recipe} selectRecipe={() => {}} />
                    ))}
                </Box>
            ),
        },
    ]

    const handleBack = () => {
        if (step > 0) setStep(step - 1)
        else goBack()
    }

    const handleNext = () => {
        switch (step) {
            case 0:
                if (checkedOption === 0) {
                    setStep(step + 1)
                    setIncompleteError(null)
                } else if (checkedOption === 1) {
                    setIncompleteError(null)
                    onClose()
                    queryClient.removeQueries(['reportSeriesTable', inboxID])
                }
                break
            case 1:
                if (input.dataDescription && input.interest) {
                    handleGenerate()
                    setIncompleteError(null)
                } else setIncompleteError('Please fill out all fields to proceed')
        }
    }

    const onGenerateError = (error) => {
        const errorMessage = error?.message || error?.error || 'Error generating summary'
        setSnackbarOpen({
            msg: errorMessage,
            severity: 'error',
        })
    }

    const handleClose = () => {
        if (step === 0) return
        onClose()
        queryClient.removeQueries(['reportSeriesTable', inboxID])
    }

    const onGenerateSuccess = () => {
        const maxAttempts = 50
        let attempts = 0
        if (canvasesAmountRef.current === null)
            canvasesAmountRef.current = canvases.canvasObjects
                ? canvases.canvasObjects.length
                : canvases.length
        setSnackbarOpen({
            severity: 'info',
            msg: 'Generating presentation. This may take a few minutes.',
        })
        const intervalId = setInterval(async () => {
            attempts += 1
            try {
                const result = await refetch()
                const updatedCanvases = result.data.canvasObjects ?? result.data ?? []
                if (canvasesAmountRef.current < updatedCanvases.length) {
                    clearInterval(intervalId)
                    if (updatedCanvases.length > 0) {
                        const newCanvas = updatedCanvases.reduce((maxCanvas, canvas) => {
                            const canvasNum = canvas?.canvasID?.slice(1)
                            const maxCanvasNum = maxCanvas?.canvasID?.slice(1)

                            return Number(canvasNum) > Number(maxCanvasNum || -Infinity)
                                ? canvas
                                : maxCanvas
                        }, updatedCanvases[0])
                        if (newCanvas?.canvasID) {
                            navigate(`${ROUTES.CANVAS_DASHBOARD}/${newCanvas.canvasID}`)
                        }
                    }
                }
                if (attempts >= maxAttempts) {
                    setSnackbarOpen({
                        severity: 'error',
                        msg: 'Failed to create presentation. Please try again later.',
                    })
                    clearInterval(intervalId)
                    handleClose()
                }
            } catch (error) {
                console.error('Error fetching canvases:', error)
                clearInterval(intervalId)
            }
        }, 5000)
    }

    const handleGenerate = () => {
        generateCanvasWithAI({
            dataDescription: input.dataDescription,
            dataQuestion: input.interest,
            inboxID,
        })
        dispatch(setCanvasPolling(true))
        queryClient.invalidateQueries(['canvases', workspaceID])
        setSnackbarOpen({
            severity: 'info',
            msg: 'Generating presentation. This may take a few minutes.',
        })
        handleClose()
    }

    const actions = (
        <Box display="flex" justifyContent="flex-end" gap="8px" width="100%">
            <Button className={'button-grey small primary-button'} onClick={handleBack}>
                {'Back'}
            </Button>
            <Button
                disabled={step === 0 && checkedOption === null}
                onClick={handleNext}
                className={'primary-button button-purple small'}
            >
                {'Next'}
            </Button>
        </Box>
    )

    return (
        <>
            <Dialog
                showCloseButton={step > 0}
                onClose={handleClose}
                style={{ minWidth: step === 1 ? 480 : 800 }}
                open={true}
                actions={actions}
                title={FLOW_STEPS[step].title}
            >
                {FLOW_STEPS[step].description && (
                    <Typography
                        sx={{
                            fontFamily: 'Inter',
                            fontWeight: 400,
                            fontSize: '14px',
                            color: '#635566',
                        }}
                    >
                        {FLOW_STEPS[step].description}
                    </Typography>
                )}
                {FLOW_STEPS[step].children}
            </Dialog>
            {snackbarOpen && (
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={() => setSnackbarOpen(null)}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                >
                    <Alert
                        onClose={() => setSnackbarOpen(null)}
                        severity={snackbarOpen?.severity}
                        variant="filled"
                    >
                        {snackbarOpen?.msg}
                    </Alert>
                </Snackbar>
            )}
        </>
    )
}
