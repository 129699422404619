import SalesforceIcon from '../../../assets/icons/Salesforce.svg'
import GoogleSheets from '../../../assets/icons/GoogleSheets.svg'
import HubspotIcon from '../../../assets/icons/Hubspot.svg'
import PipedriveIcon from '../../../assets/icons/PipedriveIcon.png'
import AttioIcon from '../../../assets/icons/attio.png'
import CopperIcon from '../../../assets/icons/copper.jpeg'
import CloseIcon from '../../../assets/icons/close.svg'
import MondayIcon from '../../../assets/icons/Monday.png'
import LinkedInIcon from '../../../assets/icons/LinkedIn.svg'
import AirtableIcon from '../../../assets/icons/Airtable.svg'
import DatabaseBlack from '../../../assets/icons/DatabaseBlack.svg'
import GoogleAnalyticsIcon from '../../../assets/icons/google-analytics.svg'
import GoogleAdsIcon from '../../../assets/icons/google-ads.svg'
import ApolloIcon from '../../../assets/icons/apollo.svg'
import QuickbooksIcon from '../../../assets/icons/Quickbooks.svg'
import SharetribeIcon from '../../../assets/icons/Sharetribe.svg'
import JiraIcon from '../../../assets/icons/Jira.svg'
import TikTokIcon from '../../../assets/icons/tiktok.png'
import OtherIcon from '../../../assets/icons/Email.svg'
import CrystalReportsIcon from '../../../assets/icons/CrystalReports.png'
import GongIcon from '../../../assets/icons/GongIcon.png'
import MarketoIcon from '../../../assets/icons/MarketoIcon.png'
import MetaIcon from '../../../assets/icons/MetaIcon.svg'
import MicrosoftDynamicsIcon from '../../../assets/icons/MicrosoftDynamics.png'
import PowerBIIcon from '../../../assets/icons/PowerBI.png'
import MicrosoftSSRSIcon from '../../../assets/icons/MicrosoftSSRS.svg'
import MLSIcon from '../../../assets/icons/MLS.png'
import NetsuiteIcon from '../../../assets/icons/Netsuite.svg'
import OutreachIcon from '../../../assets/icons/Outreach.png'
import OracleBIIcon from '../../../assets/icons/OracleBI.svg'
import WorkdayIcon from '../../../assets/icons/Workday.png'
import SproutSocialIcon from '../../../assets/icons/SproutSocial.png'
import TableauIcon from '../../../assets/icons/Tableau.svg'
import YardiIcon from '../../../assets/icons/Yardi.png'
import ZendeskIcon from '../../../assets/icons/Zendesk.svg'
import MailerLiteIcon from '../../../assets/icons/MailerLite.png'

export const SOURCES_OPTIONS = [
    {
        name: 'Salesforce',
        icon: SalesforceIcon,
        backgroundColor: '#00A1E00D',
        step: 3,
        sourceValue: 'Salesforce',
        disabled: false,
    },
    {
        name: 'Google Sheets',
        icon: GoogleSheets,
        backgroundColor: '#21A4640D',
        step: 1,
        sourceValue: 'GoogleSheets',
        disabled: false,
    },
    {
        name: 'HubSpot',
        icon: HubspotIcon,
        backgroundColor: '#FF7A590D',
        step: 3,
        sourceValue: 'HubSpot',
        disabled: false,
    },
    {
        name: 'Pipedrive',
        icon: PipedriveIcon,
        backgroundColor: '#21A4640D',
        step: 3,
        sourceValue: 'Pipedrive',
        disabled: false,
    },
    {
        name: 'Attio',
        icon: AttioIcon,
        backgroundColor: '#21A4640D',
        step: 2,
        sourceValue: 'Attio',
        disabled: false,
    },
    {
        name: 'Copper',
        icon: CopperIcon,
        backgroundColor: '#21A4640D',
        step: 2,
        sourceValue: 'Copper',
        disabled: false,
    },
    {
        name: 'Close',
        icon: CloseIcon,
        backgroundColor: '#FF7A590D',
        step: 2,
        sourceValue: 'Close',
        disabled: false,
    },
    {
        name: 'Monday',
        icon: MondayIcon,
        backgroundColor: '#FF7A590D',
        step: 2,
        sourceValue: 'Monday',
        preferredSpelling: 'monday.com',
        disabled: false,
    },
    {
        name: 'LinkedIn',
        icon: LinkedInIcon,
        backgroundColor: '#FF7A590D',
        step: 1,
        sourceValue: 'LinkedIn',
        disabled: false,
    },
    {
        name: 'Airtable',
        icon: AirtableIcon,
        backgroundColor: '#0000000D',
        step: 2,
        sourceValue: 'Airtable',
        disabled: false,
    },
    {
        name: 'Database',
        icon: DatabaseBlack,
        backgroundColor: '#0000000D',
        step: 1,
        sourceValue: 'Database',
        disabled: false,
    },
    {
        name: 'Google Analytics',
        icon: GoogleAnalyticsIcon,
        backgroundColor: 'rgba(38, 132, 255, 0.05)',
        step: 2,
        sourceValue: 'GoogleAnalytics',
        disabled: false,
    },
    {
        name: 'Google Ads',
        icon: GoogleAdsIcon,
        backgroundColor: 'rgba(38, 132, 255, 0.05)',
        step: 2,
        sourceValue: 'GoogleAds',
        disabled: false,
    },
    /*{
        name: 'Google Data Studio',
        icon: GoogleDataStudioIcon,
        backgroundColor: 'rgba(38, 132, 255, 0.05)',
        step: 1,
        sourceValue: 'GoogleDataStudio',
        disabled: false,
    },*/
    {
        name: 'Apollo',
        icon: ApolloIcon,
        backgroundColor: 'rgba(38, 132, 255, 0.05)',
        step: 3,
        sourceValue: 'Apollo',
        disabled: false,
    },
    {
        name: 'Quickbooks',
        icon: QuickbooksIcon,
        backgroundColor: '#00A1E00D',
        step: 2,
        sourceValue: 'Quickbooks',
        preferredSpelling: 'QuickBooks',
        // for now, leave this as off, unless QB fights us hard on this (gj 11/25)
        allowSingleInstall: false,
        disabled: false,
    },
    {
        name: 'Sharetribe',
        icon: SharetribeIcon,
        backgroundColor: 'rgba(38, 132, 255, 0.05)',
        step: 1,
        sourceValue: 'Sharetribe',
        disabled: false,
    },
    {
        name: 'Jira',
        icon: JiraIcon,
        backgroundColor: '#0000000D',
        step: 2,
        sourceValue: 'Jira',
        disabled: false,
    },
    {
        name: 'TikTok',
        icon: TikTokIcon,
        backgroundColor: '#00A1E00D',
        step: 2,
        sourceValue: 'TikTok',
        disabled: false,
    },
    {
        name: 'MailerLite',
        icon: MailerLiteIcon,
        backgroundColor: '#00A1E00D',
        step: 2,
        sourceValue: 'MailerLite',
        disabled: false,
    },
    {
        name: 'Other',
        icon: OtherIcon,
        backgroundColor: '#00A1E00D',
        step: 1,
        sourceValue: 'Other',
        disabled: false,
    },
    {
        name: 'Crystal Reports',
        icon: CrystalReportsIcon,
        backgroundColor: '#00A1E00D',
        step: 1,
        sourceValue: 'CrystalReports',
        disabled: true,
    },
    {
        name: 'Gong',
        icon: GongIcon,
        backgroundColor: '#00A1E00D',
        step: 1,
        sourceValue: 'Gong',
        disabled: true,
    },
    {
        name: 'Marketo',
        icon: MarketoIcon,
        backgroundColor: '#00A1E00D',
        step: 1,
        sourceValue: 'Marketo',
        disabled: true,
    },
    {
        name: 'Meta (Facebook Analytics)',
        icon: MetaIcon,
        backgroundColor: '#00A1E00D',
        step: 1,
        sourceValue: 'Meta',
        disabled: true,
    },
    {
        name: 'Microsoft Dynamics',
        icon: MicrosoftDynamicsIcon,
        backgroundColor: '#00A1E00D',
        step: 1,
        sourceValue: 'MicrosoftDynamics',
        disabled: true,
    },
    {
        name: 'Power BI',
        icon: PowerBIIcon,
        backgroundColor: '#00A1E00D',
        step: 1,
        sourceValue: 'PowerBI',
        disabled: true,
    },
    {
        name: 'Microsoft SSRS',
        icon: MicrosoftSSRSIcon,
        backgroundColor: '#00A1E00D',
        step: 1,
        sourceValue: 'MicrosoftSSRS',
        disabled: true,
    },
    {
        name: 'MLS Realtor',
        icon: MLSIcon,
        backgroundColor: '#00A1E00D',
        step: 1,
        sourceValue: 'MLS',
        disabled: true,
    },
    {
        name: 'Netsuite',
        icon: NetsuiteIcon,
        backgroundColor: '#00A1E00D',
        step: 1,
        sourceValue: 'Netsuite',
        disabled: true,
    },
    {
        name: 'Outreach',
        icon: OutreachIcon,
        backgroundColor: '#00A1E00D',
        step: 1,
        sourceValue: 'Outreach',
        disabled: true,
    },
    {
        name: 'Oracle BI',
        icon: OracleBIIcon,
        backgroundColor: '#00A1E00D',
        step: 1,
        sourceValue: 'OracleBI',
        disabled: true,
    },
    {
        name: 'Workday',
        icon: WorkdayIcon,
        backgroundColor: '#00A1E00D',
        step: 1,
        sourceValue: 'Workday',
        disabled: true,
    },
    {
        name: 'Sprout Social',
        icon: SproutSocialIcon,
        backgroundColor: '#00A1E00D',
        step: 1,
        sourceValue: 'SproutSocial',
        disabled: true,
    },
    {
        name: 'Tableau',
        icon: TableauIcon,
        backgroundColor: '#00A1E00D',
        step: 1,
        sourceValue: 'Tableau',
        disabled: true,
    },
    {
        name: 'Yardi',
        icon: YardiIcon,
        backgroundColor: '#00A1E00D',
        step: 1,
        sourceValue: 'Yardi',
        disabled: true,
    },
    {
        name: 'Zendesk',
        icon: ZendeskIcon,
        backgroundColor: '#00A1E00D',
        step: 1,
        sourceValue: 'Zendesk',
        disabled: true,
    },
]
